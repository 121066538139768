import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import { Pagination } from "components/pagination"

import moment from "moment"

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <div className="news-page design-style">
        <div className="contents">
          {/* ページタイトル */}
          <div className="fw-b tx-center page-title">
            <h1 className="title fs-40 sp-fz-30 ls--0-3 e">News</h1>
            <p className="sub-title fs-20 sp-fz-14 pc-mt10 sp-mt5">
              サービスのお知らせ
            </p>
          </div>
          {/* /ページタイトル */}
          <div className="inner-contents">
            <ul className="nav-menu">
              <li className="nav-item selected">
                <p>お知らせ</p>
              </li>
              <Link to="/media" className="nav-item">
                <li>メディア掲載</li>
              </Link>
            </ul>
            <section className="news-contents">
              {/* ここにニュース一覧(news) */}
              <ul className="news-list">
                {data.allStrapiInformation.edges.map(({ node }) => (
                  <li key={node.strapi_id} className="list-item">
                    <Link to={`/news/${node.slug}`}>
                      <div className="news-item">
                        <p className="published-date e">
                          {moment(node.publishedAt).format(`YYYY.MM.DD`)}
                        </p>
                        <p className="news-title">{node.title}</p>
                      </div>
                    </Link>
                  </li>
                ))}
                <Pagination
                  totalCount={data.allStrapiInformation.pageInfo.totalCount}
                  currentPage={data.allStrapiInformation.pageInfo.currentPage}
                  perPage={15}
                  pageType="news"
                />
              </ul>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="News ー 最新情報をお届けいたします。"
    description="Baluko Laundry Placeの店舗で開催するお得なセールやキャンペーンのお知らせや、新しいサービスなど、最新情報を配信いたします。"
  />
)
export default NewsPage

export const query = graphql`
  query ($limit: Int = 15, $skip: Int = 0) {
    allStrapiInformation(
      sort: { fields: [publishedAt], order: DESC }
      filter: { category: { uid: { in: ["news", "cafe"] } } }
      skip: $skip
      limit: $limit
    ) {
      pageInfo {
        totalCount
        currentPage
      }
      edges {
        node {
          slug
          strapi_id
          title
          publishedAt
          category {
            uid
          }
        }
      }
    }
  }
`
