import React from "react"
import { Link } from "gatsby"

export const Pagination = ({ totalCount, currentPage, perPage, pageType }) => {
  const range = (start, end) =>
    [...Array(end - start + 1)].map((_, i) => start + i)
  const totalPage = Math.ceil(totalCount / perPage)
  const paginateRange = 2
  const previousIcon =
    "https://balukoweb.blob.core.windows.net/images/common/pagenation/previus-arrow.svg"
  const nextIcon =
    "https://balukoweb.blob.core.windows.net/images/common/pagenation/next-arrow.svg"
  return (
    <ul className="pagination">
      {currentPage > 1 && (
        <li className="pager">
          <Link
            to={`/${pageType}/page/${currentPage - 1}`}
            className="prev-page"
          >
            <img className="previous-icon" src={previousIcon} alt="" />
          </Link>
        </li>
      )}
      {currentPage > paginateRange + 1 && (
        <>
          <li className="pager e">
            <Link to={`/${pageType}/page/1`}>1</Link>
          </li>
          {currentPage - paginateRange > 2 && (
            <li className="skip-point">
              <span>...</span>
            </li>
          )}
        </>
      )}
      {range(1, totalPage).map((number, index) => (
        <>
          {number <= currentPage + paginateRange &&
            number >= currentPage - paginateRange && (
              <li
                className={`${currentPage === number ? "current" : ""} pager e`}
                key={index}
              >
                <Link to={`/${pageType}/page/${number}`}>{number}</Link>
              </li>
            )}
        </>
      ))}

      {currentPage + paginateRange < totalPage && (
        <>
          <li className="skip-point">
            <span>...</span>
          </li>
          <li className="pager e">
            <Link to={`/${pageType}/page/${totalPage}`}>{totalPage}</Link>
          </li>
        </>
      )}

      {currentPage < totalPage && (
        <li>
          <Link
            to={`/${pageType}/page/${currentPage + 1}`}
            className="next-page"
          >
            <img className="next-icon" src={nextIcon} alt="" />
          </Link>
        </li>
      )}
    </ul>
  )
}
